import React, { useState, useEffect } from 'react';
import {TextField, Button, Box, Snackbar} from '@mui/material';
import {base_url, base_url_long} from "../../index";
import {Link, useParams} from "react-router-dom";
import Alert from "@mui/material/Alert";

const VideoMarking = () => {
    const [videoData, setVideoData] = useState(null);
    const [markersData, setMarkersData] = useState(null);
    const [formValues, setFormValues] = useState({
    firstTime: '',
    secondTime: '',
    firstExtraTime: '',
    secondExtraTime: ''
  });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    let { id } = useParams();

    const fetchVideo = async () => {
        const response = await fetch(`${base_url}match/${id}/`)
        const data = await response.json()
        await setVideoData(data.videos[0])
        console.log(videoData)
    }

    const fetchMarkers = async () => {
          try {
            const response = await fetch(`${base_url_long}registrator/match_markers_fast/${id}`);
            const data = await response.json();
            const filteredMarkers = data.markers.filter(marker =>
              [1, 75, 81, 85].includes(marker.action.id)
            );

            await setMarkersData(filteredMarkers);
            console.log(filteredMarkers);
          } catch (error) {
            console.error('Error fetching markers:', error);
          }
        };

    useEffect(() => {
        fetchVideo()
        fetchMarkers()
    }, [])

    const convertToSeconds = (time) => {
      if (!isNaN(time)) {
        return Number(time);
      }

      // Если формат MM:SS или HH:MM:SS
      const timeParts = time.split(':').map(Number);

      if (timeParts.length === 2) {
        const [minutes, seconds] = timeParts;
        return minutes * 60 + seconds;
      } else if (timeParts.length === 3) {
        const [hours, minutes, seconds] = timeParts;
        return hours * 3600 + minutes * 60 + seconds;
      }
      return null;
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
          ...formValues,
          [name]: value
        });
      };

    const handleSubmit = async (event) => {
      event.preventDefault();

      const markerUpdates = [
        { actionId: 1, time: convertToSeconds(formValues.firstTime) },
        { actionId: 75, time: convertToSeconds(formValues.secondTime) },
        { actionId: 81, time: convertToSeconds(formValues.firstExtraTime) },
        { actionId: 85, time: convertToSeconds(formValues.secondExtraTime) }
        ];

      const patchRequests = markersData.map(marker => {
        const update = markerUpdates.find(update => update.actionId === marker.action.id);

        if (update && update.time) {
          return fetch(`${base_url_long}registrator/marker/${marker.id}/`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ timing: update.time })
          });
        }

        return null;
      }).filter(Boolean);

      try {
        await Promise.all(patchRequests);
        console.log('Markers updated successfully');
        setSuccessMessage('Markers updated successfully');  // Успешное выполнение

      } catch (error) {
          setErrorMessage('Error updating markers');  // Ошибка при обновлении
        console.error('Error updating markers:', error);

      }
};

    const handleClose = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
      <>
  <Box style={{ marginTop: 25, width: 1066, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {videoData ? (
      <video width="70%" controls style={{ marginRight: 20 }}>
        <source src={videoData.link} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ) : (
      <p>Loading video...</p>
    )}

    <form onSubmit={handleSubmit} style={{ width: '35%' }}>
      <TextField
        label="First Time"
        name="firstTime"
        value={formValues.firstTime}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Second Time"
        name="secondTime"
        value={formValues.secondTime}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="First Extra Time"
        name="firstExtraTime"
        value={formValues.firstExtraTime}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Second Extra Time"
        name="secondExtraTime"
        value={formValues.secondExtraTime}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Submit
      </Button>
    </form>
  </Box>

  <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
      {successMessage}
    </Alert>
  </Snackbar>

  <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
      {errorMessage}
    </Alert>
  </Snackbar>
          </>
);

};

export default VideoMarking;
